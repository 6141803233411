import React,{useRef} from "react"
import IdleTimer from "react-idle-timer"

function IdleTimerContainer() {
    const idleTimeRef =useRef(null)
   const onIdle =()=>{
       console.log("user is idle >>>>>>>>>>>>>>>>")
       localStorage.removeItem("accessToken");
       localStorage.removeItem("refreshToken");
       window.location.reload();

   }
 
    return(
        <div>
        <IdleTimer Ref={idleTimeRef} timeout={1800*1000} onIdle={onIdle}></IdleTimer>
        
        </div>
    )
}

export default IdleTimerContainer