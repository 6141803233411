import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function Navbar() {
  const data = localStorage.getItem("admin");

  if (!data) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("admin");
    window.location.reload();
  }
  const admin = JSON.parse(data);
  console.log("aaaaaaaaaaaaaaaaaaaaaaa", admin);
  return (
    <div
      className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
    >
      <div className="brand flex-column-auto" id="kt_brand">
        <NavLink to="/" className="brand-logo">
          {/* <h4>NCPD</h4> */}
          <img alt="Logo" src="../assets/media/miniLogo.webp" />
        </NavLink>
        <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
          <span className="svg-icon svg-icon svg-icon-xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
                />
                <path
                  d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.3"
                  transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
                />
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div
        className="aside-menu-wrapper flex-column-fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="aside-menu my-4"
          data-menu-vertical={1}
          data-menu-scroll={1}
          data-menu-dropdown-timeout={500}
        >
          <ul className="menu-nav">
            <li className="menu-item" aria-haspopup="true">
              <NavLink to="/dashboard" className="menu-link">
                <span className="svg-icon menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            {admin.isSuperAdmin && (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/subAdmin" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">SubAdmin</span>
                </NavLink>
              </li>
            )}

            {/* users nav */}
            {admin.isSuperAdmin || admin.permissions.includes("Users") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Users</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink to="/Getuser" className="menu-link menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">User Listing</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
              (admin.permissions.includes("Rights") ? (
                <li
                  className="menu-item menu-item-submenu"
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a href="javascript:;" className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M14,13.381038 L14,3.47213595 L7.99460483,15.4829263 L14,13.381038 Z M4.88230018,17.2353996 L13.2844582,0.431083506 C13.4820496,0.0359007077 13.9625881,-0.12427877 14.3577709,0.0733126292 C14.5125928,0.15072359 14.6381308,0.276261584 14.7155418,0.431083506 L23.1176998,17.2353996 C23.3152912,17.6305824 23.1551117,18.1111209 22.7599289,18.3087123 C22.5664522,18.4054506 22.3420471,18.4197165 22.1378777,18.3482572 L14,15.5 L5.86212227,18.3482572 C5.44509941,18.4942152 4.98871325,18.2744737 4.84275525,17.8574509 C4.77129597,17.6532815 4.78556182,17.4288764 4.88230018,17.2353996 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.000087, 9.191034) rotate(-315.000000) translate(-14.000087, -9.191034) "
                          />
                        </g>
                      </svg>
                    </span>

                    <span className="menu-text">Rights</span>
                    <i className="menu-arrow" />
                  </a>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li
                        className="menu-item menu-item-submenu"
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink
                          to="/addRights"
                          className="menu-link menu-toggle"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Listing</span>
                          {/* <i className="menu-arrow" /> */}
                        </NavLink>
                      </li>
                      <li
                        className="menu-item menu-item-submenu"
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                      >
                        <NavLink
                          to="/GetRights"
                          className="menu-link menu-toggle"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Rights Listings</span>
                          {/* <i className="menu-arrow" /> */}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              ))}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Registrations") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                          fill="#000000"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="9"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="9"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="13"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="13"
                          width="7"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="7"
                          y="17"
                          width="2"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="10"
                          y="17"
                          width="7"
                          height="2"
                          rx="1"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Registrations</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/getRegistrants"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          Recieved Registrations
                        </span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin || admin.permissions.includes("News") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M11.2600599,5.81393408 L2,16 L22,16 L12.7399401,5.81393408 C12.3684331,5.40527646 11.7359848,5.37515988 11.3273272,5.7466668 C11.3038503,5.7680094 11.2814025,5.79045722 11.2600599,5.81393408 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M12.0056789,15.7116802 L20.2805786,6.85290308 C20.6575758,6.44930487 21.2903735,6.42774054 21.6939717,6.8047378 C21.8964274,6.9938498 22.0113578,7.25847607 22.0113578,7.535517 L22.0113578,20 L16.0113578,20 L2,20 L2,7.535517 C2,7.25847607 2.11493033,6.9938498 2.31738608,6.8047378 C2.72098429,6.42774054 3.35378194,6.44930487 3.7307792,6.85290308 L12.0056789,15.7116802 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">News</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink to="/AddNews" className="menu-link menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Add News</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink to="/getNews" className="menu-link menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">News Listings</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin || admin.permissions.includes("Directories") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M6,2 L18,2 C19.6568542,2 21,3.34314575 21,5 L21,19 C21,20.6568542 19.6568542,22 18,22 L6,22 C4.34314575,22 3,20.6568542 3,19 L3,5 C3,3.34314575 4.34314575,2 6,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Directories</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/addDirectory"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Add Listings</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/GetDirectory"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Directories Listings</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Emergency Contact") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M11.914857,14.1427403 L14.1188827,11.9387145 C14.7276032,11.329994 14.8785122,10.4000511 14.4935235,9.63007378 L14.3686433,9.38031323 C13.9836546,8.61033591 14.1345636,7.680393 14.7432841,7.07167248 L17.4760882,4.33886839 C17.6713503,4.14360624 17.9879328,4.14360624 18.183195,4.33886839 C18.2211956,4.37686904 18.2528214,4.42074752 18.2768552,4.46881498 L19.3808309,6.67676638 C20.2253855,8.3658756 19.8943345,10.4059034 18.5589765,11.7412615 L12.560151,17.740087 C11.1066115,19.1936265 8.95659008,19.7011777 7.00646221,19.0511351 L4.5919826,18.2463085 C4.33001094,18.1589846 4.18843095,17.8758246 4.27575484,17.613853 C4.30030124,17.5402138 4.34165566,17.4733009 4.39654309,17.4184135 L7.04781491,14.7671417 C7.65653544,14.1584211 8.58647835,14.0075122 9.35645567,14.3925008 L9.60621621,14.5173811 C10.3761935,14.9023698 11.3061364,14.7514608 11.914857,14.1427403 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Emergency Contact</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/AddContact"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Add Contact</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/GetContact"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Contact Listings</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Static Pages") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                        <rect
                          fill="#000000"
                          x="6"
                          y="11"
                          width="9"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          x="6"
                          y="15"
                          width="5"
                          height="2"
                          rx="1"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Static Pages</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink to="/GetPages" className="menu-link menu-toggle">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Page Listings</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin || admin.permissions.includes("Complaints") ? (
              <li
                className="menu-item menu-item-submenu"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" className="menu-link menu-toggle">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                  </span>

                  <span className="menu-text">Complaints</span>
                  <i className="menu-arrow" />
                </a>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-submenu"
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    >
                      <NavLink
                        to="/getComplaints"
                        className="menu-link menu-toggle"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Recieved Complaints</span>
                        {/* <i className="menu-arrow" /> */}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Notification") ? (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/notification" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Notification</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin || admin.permissions.includes("Transports") ? (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/transports" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Transports</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Advertisement") ? (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/advertisement" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Advertisement</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Advertisement") ? (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/getJobs" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Jobs</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {admin.isSuperAdmin ||
            admin.permissions.includes("Advertisement") ? (
              <li className="menu-item" aria-haspopup="true">
                <NavLink to="/getEvents" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z"
                          fill="#000000"
                        >
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={10}
                            y={16}
                            width={4}
                            height={4}
                            rx={2}
                          ></rect>
                        </path>
                      </g>
                    </svg>
                  </span>
                  <span className="menu-text">Events</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

// <li
//               className="menu-item menu-item-submenu"
//               aria-haspopup="true"
//               data-menu-toggle="hover"
//             >
//               <a href="javascript:;" className="menu-link menu-toggle">
//                 <span className="svg-icon menu-icon">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     xmlnsXlink="http://www.w3.org/1999/xlink"
//                     width="24px"
//                     height="24px"
//                     viewBox="0 0 24 24"
//                     version="1.1"
//                   >
//                     <g
//                       stroke="none"
//                       strokeWidth={1}
//                       fill="none"
//                       fillRule="evenodd"
//                     >
//                       <rect x={0} y={0} width={24} height={24} />
//                       <rect
//                         fill="#000000"
//                         opacity="0.3"
//                         x={4}
//                         y={5}
//                         width={16}
//                         height={2}
//                         rx={1}
//                       />
//                       <rect
//                         fill="#000000"
//                         opacity="0.3"
//                         x={4}
//                         y={13}
//                         width={16}
//                         height={2}
//                         rx={1}
//                       />
//                       <path
//                         d="M5,9 L13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L13,17 C13.5522847,17 14,17.4477153 14,18 C14,18.5522847 13.5522847,19 13,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z"
//                         fill="#000000"
//                       />
//                     </g>
//                   </svg>
//                   {/*end::Svg Icon*/}
//                 </span>
//                 <span className="menu-text">Categories</span>
//                 <i className="menu-arrow" />
//               </a>
//               <div className="menu-submenu">
//                 <ul className="menu-subnav">
//                   <li
//                     className="menu-item menu-item-submenu"
//                     aria-haspopup="true"
//                     data-menu-toggle="hover"
//                   >
//                     <NavLink to="/categories" className="menu-link menu-toggle">
//                       <i className="menu-bullet menu-bullet-dot">
//                         <span />
//                       </i>
//                       <span className="menu-text">Categories</span>
//                       {/* <i className="menu-arrow" /> */}
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>

//               <div className="menu-submenu">
//                 <ul className="menu-subnav">
//                   <li
//                     className="menu-item menu-item-submenu"
//                     aria-haspopup="true"
//                     data-menu-toggle="hover"
//                   >
//                     <NavLink
//                       to="/addCategories"
//                       className="menu-link menu-toggle"
//                     >
//                       <i className="menu-bullet menu-bullet-dot">
//                         <span />
//                       </i>
//                       <span className="menu-text">Add Categories</span>
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>

//               <div className="menu-submenu">
//                 <ul className="menu-subnav">
//                   <li
//                     className="menu-item menu-item-submenu"
//                     aria-haspopup="true"
//                     data-menu-toggle="hover"
//                   >
//                     <NavLink
//                       to="/addservices"
//                       className="menu-link menu-toggle"
//                     >
//                       <i className="menu-bullet menu-bullet-dot">
//                         <span />
//                       </i>
//                       <span className="menu-text">Add Services</span>
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>
//             </li>
