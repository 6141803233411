import React from "react";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const singleUser = React.lazy(() => import("./pages/users/singleUser"));
const Getuser = React.lazy(() => import("./pages/users/UsersListing"));

const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));

const addRights = React.lazy(() => import("./pages/rights/addRights"));
const getRights = React.lazy(() => import("./pages/rights/getRights"));
const SingleRights = React.lazy(() => import("./pages/rights/singleRights"));
const editRights = React.lazy(() => import("./pages/rights/editRights"));

const AddNews = React.lazy(() => import("./pages/news/addNews"));
const GetNews = React.lazy(() => import("./pages/news/GetNews"));
const singleNews = React.lazy(() => import("./pages/news/singleNews"));
const editNews = React.lazy(() => import("./pages/news/editNews"));

const GetRegistrants = React.lazy(() =>
  import("./pages/registrations/getRegistration")
);
const singleRegistrants = React.lazy(() =>
  import("./pages/registrations/registrationInfo")
);

const Complaint = React.lazy(() => import("./pages/complaint/complaint"));
const singleComplaints = React.lazy(() =>
  import("./pages/complaint/singleComplaint")
);

const addDirectories = React.lazy(() =>
  import("./pages/Directory/addDirectrory")
);
const GetDirectory = React.lazy(() => import("./pages/Directory/getDirectory"));
const SingleDirectory = React.lazy(() =>
  import("./pages/Directory/singleDirectory")
);
const editDirectory = React.lazy(() =>
  import("./pages/Directory/editDirectory")
);

const AddContact = React.lazy(() => import("./pages/Contact/contact"));
const GetContact = React.lazy(() => import("./pages/Contact/getContact"));
const SingleContact = React.lazy(() => import("./pages/Contact/singleContact"));
const EditContact = React.lazy(() => import("./pages/Contact/editContact"));

const GetPages = React.lazy(() => import("./pages/editPages/getPages"));
const editPages = React.lazy(() => import("./pages/editPages/editPage"));

const notification = React.lazy(() => import("./pages/Notification"));
const transport = React.lazy(() => import("./pages/transport/transportList"));
const advertisementList = React.lazy(() =>
  import("./pages/Advertisement/AdvertisementList")
);
const addJob = React.lazy(() => import("./pages/Jobs/CreateJobs"));
const subAdmin = React.lazy(() => import("./pages/SubAdmin/SubAdminList"));
const jobsList = React.lazy(() => import("./pages/Jobs/JobList"));
const events = React.lazy(() => import("./pages/Events/EventList"));
const addEvent = React.lazy(() => import("./pages/Events/AddEvent"));

const routes = [
  { path: "/addEvent", name: "Create job", component: addEvent },
  { path: "/createJob", name: "Create job", component: addJob },
  { path: "/getJobs", name: "Create job", component: jobsList },
  { path: "/getEvents", name: "Create job", component: events },
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  { path: "/singleUser/:id", name: "UserDetails", component: singleUser },
  { path: "/Getuser", name: "Register Page", component: Getuser },

  {
    path: "/ChangePassword",
    exact: true,
    name: "changePassword",
    component: ChangePassword,
  },

  { path: "/addRights", name: "Rights", component: addRights },
  { path: "/GetRights", name: "Rights", component: getRights },
  { path: "/singleRights/:id", name: "Rights", component: SingleRights },
  { path: "/editRights/:id", name: "Rights", component: editRights },

  { path: "/AddNews", name: "Rights", component: AddNews },
  { path: "/getNews", name: "Rights", component: GetNews },
  { path: "/singleNews/:id", name: "UserDetails", component: singleNews },
  { path: "/editNews/:id", name: "UserDetails", component: editNews },

  { path: "/getRegistrants", name: "Rights", component: GetRegistrants },
  {
    path: "/singleRegistrants/:id",
    name: "Rights",
    component: singleRegistrants,
  },

  { path: "/getComplaints", name: "Rights", component: Complaint },
  { path: "/singleComplaint/:id", name: "Rights", component: singleComplaints },

  { path: "/addDirectory", name: "directory", component: addDirectories },
  { path: "/GetDirectory", name: "directory", component: GetDirectory },
  {
    path: "/SingleDirectory/:id",
    name: "directory",
    component: SingleDirectory,
  },
  { path: "/editDirectory/:id", name: "directory", component: editDirectory },

  { path: "/AddContact", name: "Contact", component: AddContact },
  { path: "/GetContact", name: "Contact", component: GetContact },
  { path: "/SingleContact/:id", name: "contact", component: SingleContact },
  { path: "/editContact/:id", name: "contact", component: EditContact },

  { path: "/GetPages", name: "pages", component: GetPages },
  { path: "/editPages/:id", name: "pages", component: editPages },
  { path: "/notification", name: "notification", component: notification },
  { path: "/transports", name: "transports", component: transport },
  {
    path: "/advertisement",
    name: "Advertisement List",
    component: advertisementList,
  },
  {
    path: "/subAdmin",
    name: "Advertisement List",
    component: subAdmin,
  },
];

export default routes;
